import React, { useState } from 'react';


import Logo from '../images/logo.png';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faCopyright, faPhone } from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../images/homebody.png';

function IdInput(props) {
    const [staySigned, setStaySigned] = useState(false)

  return (
    <div style={{marginTop:49, marginLeft:27}}>
    <label style={{fontSize:18, fontFamily:'Inter', fontWeight:500}}>{props.label}</label>
    <div className='inputtext'>
      <div className='iconBox'>{props.leftIcon}</div>
      <input {...props}  />
     <div className='iconBox'>{props.rightIcon}</div> 
    </div>
    
</div>
  )
}

export default IdInput