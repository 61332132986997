import React, { useState , useEffect, useContext} from 'react';



import Logo from '../../images/logo.png';
import Naked from '../../images/naked.png';
import NigeriaLogo from '../../images/nigerialogo.png';
import Bvn from  '../../images/bvn.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faCloud,
    faPhone} from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../../images/homebody.png';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { ShareContext } from '../../context/sharecontext';

import IdLayout from '../../components/_layout';
import * as Constants from '../../Constants';
import tinData from '../../data/finger.json';
import IdInput from '../../components/idInput';
import IdSelect from '../../components/idSelect';

function Reports() {
    const { profile,raw,activeType } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [param, setParam] = useState({startdate:new Date(), enddate:new Date, type:''})
    const [report, setReport] = useState([]);
   // const {type} = useParams(); 
    
    //const data=bvnData.ResponseData;

  console.log(tinData);

    useEffect(() => { 
        
        Constants.seo({title:'Driver License Search',metaDescription:''}); 
    }, []);

    const onPrint=()=>{
        window.print();
    }
    const types=[{value:'all',text:'All Verifiaction Type'},{value:'nin',text:'NIN'}, {value:'nin-phone',text:'Nin Search by Phone'}, {value:'nin-bvn',text:'Nin Search by BVN'}, 
       {value:'vnin',text:'Virtual Nin'}, {value:'nin-phone',text:'Nin Search by Phone'}]

       const usertype =[{value:'all', text:'All User Type'},{value:'customer', text:'Customer'}, {value:'agent', text:'Agent'},  {value:'admin', text:'admin'}]

      const onSearch=()=>{

      } 

  return (

   <IdLayout active={activeType} profile={profile}>
    <div style={{display:'flex',  flex:1,padding:8, flexDirection:'column'}}>
            <div style={{display:'flex', backgroundColor:'white', flex:1, flexDirection:'column'}}>

          <div>
            <h2>Report</h2>
            <div>
                <IdInput type='date' label='startdate' value={param.startdate} onChange={(e)=> setParam({...param, startdate:e.target.value})}/>
                <IdInput type='date' label='enddate' value={param.enddate} onChange={(e)=> setParam({...param, enddate:e.target.value})}/>
                <IdSelect onChange={(e)=> setParam({...param, verifytype:e.target.value})} disabledText="Select Type" items={types} label='Report Type' />
                <IdSelect onChange={(e)=> setParam({...param, usertype:e.target.value})} disabledText="User Type" items={usertype} label='User Type' />

                <button className='greenbutton' style={{marginTop:'auto', marginBottom:'auto'}} onClick={onSearch}>Get Report</button>
            </div>
            <div>

            </div>
          </div>
              
               
            </div>

<div>
<div style={{display:'flex', justifyContent:'center', marginBottom:16}}>
                                <button className='greenbutton no-print' onClick={onPrint} >Print</button>
                                
                              </div>
</div>

    </div>
   </IdLayout>
  )
}

export default Reports