import React, { useState , useEffect, useContext} from 'react';
import '../css/sidebar.css';


import Logo from '../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp} from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../images/homebody.png';
import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';



function IdSideBar(props) {

    
   // console.log(`the active props ${props.active} if it contain nin- ${props.active.includes('nin-')}`);

    const [isNin, setIsNin]=useState(false);

   const navigate = useNavigate();
    const onLogin=()=>{
        navigate('/login');
    }

    const onPage=(type)=>{
        navigate('/search/'+type);

    }

    const settings=()=>{
        navigate('/settings');
    }
    const reports=()=>{
        navigate('/reports');
    }
    useEffect(() => {
       var status= props.active?.includes('nin-');
       setIsNin(status)
       console.log(`the active ${props.active}`)
        }, [props.active]);

        const onResult=()=>{
            navigate('/searchresult/nin-vir');
        }

  return (
    <div className='sidebar no-print'>
         <img src={Logo}/>
         <div className='items'>
            <div className={props.active=='dashboard'?'itemactive':'item'} onClick={()=>navigate('/dashboard')}>
               
                <div>
                     <FontAwesomeIcon icon={faDashboard} /> <span>Dashboard</span> 
                </div>
            </div>
            <div className={'item'}  onClick={()=>setIsNin(!isNin)}>
                <div>
                    <FontAwesomeIcon icon={faBookBookmark}  /> <span>NIN</span> 
                </div>
                
             {isNin &&  <div className='sub'>
                    <div className={props.active=='nin-nin'?'subactive':'subitem'}  onClick={()=>onPage('nin-nin')}>
                         <span>NIN Search</span> 
                    </div>
                    <div className={props.active=='nin-phone'?'subactive':'subitem'}  onClick={()=>onPage('nin-phone')}>
                        <span>Phone</span> 
                    </div>
                    <div className={props.active=='nin-doc'?'subactive':'subitem'}  onClick={()=>onPage('nin-doc')}>
                        <span>Document Search</span> 
                    </div>
                    <div className={props.active=='nin-fin'?'subactive':'subitem'}  onClick={()=>onPage('nin-fin')}>
                        <span>Finger Print</span> 
                    </div>
                    <div className={props.active=='nin-demo'?'subactive':'subitem'}  onClick={()=>onPage('nin-demo')}>
                        <span>Demography</span> 
                    </div>
                    <div className={props.active=='nin-bvn'?'subactive':'subitem'}  onClick={()=>onPage('nin-bvn')}>
                        <span>BVN</span> 
                    </div>
                    <div className={props.active=='nin-vir'?'subactive':'subitem'}  onClick={()=>onPage('nin-vir')}>
                        <span>Vitual NIN</span> 
                    </div>
                </div>}  
            </div>
            <div className={props.active=='bvn'?'itemactive':'item'}  onClick={()=>onPage('bvn')}>
            <div>
                <FontAwesomeIcon icon={faPuzzlePiece}  /> <span>BVN</span>     
                    </div>
                
            </div>
            <div className={props.active=='passport'?'itemactive':'item'}  onClick={()=>onPage('passport')}>
                <div>
                    <FontAwesomeIcon icon={faCalendar}  /> <span>International Passport</span> 
                </div>
                
            </div>
            <div className={props.active=='tin'?'itemactive':'item'}  onClick={()=>onPage('tin')}>
            <div>
                <FontAwesomeIcon icon={faPuzzlePiece}  /> <span>TIN</span>     
                    </div>
                
            </div>
            <div className={props.active=='cac'?'itemactive':'item'}  onClick={()=>onPage('cac')}>
            <div>
                  <FontAwesomeIcon icon={faShieldAlt}  /> <span>CAC</span>   
                    </div>
                
            </div>
            <div className={props.active=='driver'?'itemactive':'item'}  onClick={()=>onPage('driver')}>
            <div>
                  <FontAwesomeIcon icon={faDiceOne}  /> <span>Driver's Licence</span>  
                    </div>
                 
            </div>
            <div className={props.active=='voter'?'itemactive':'item'}  onClick={()=>onPage('voter')}>
            <div>
                 <FontAwesomeIcon icon={faBahtSign}  /> <span>Voter's Registration</span>    
                    </div>
                
            </div>
            <div className={props.active=='report'?'itemactive':'item'}  onClick={reports}>
            <div>
                  <FontAwesomeIcon icon={faChartBar}  /> <span>Reports</span>   
                    </div>
                
            </div>
            <div className={props.active=='settings'?'itemactive':'item'} onClick={settings} >
            <div>
                  <FontAwesomeIcon icon={faTools}  /> <span>Settings</span>   
                    </div>
            </div>
           {/*  <div className={props.active=='setting'?'itemactive':'item'} onClick={onResult} >
            <div>
                  <FontAwesomeIcon icon={faTools}  /> <span>Check Result</span>   
                    </div>
            </div> */}
         </div>
     </div>
  )
}

export default IdSideBar