import React, { useState,useContext, useEffect } from 'react';


import Logo from '../images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faCheck } from '@fortawesome/free-solid-svg-icons';
import HomeImg from '../images/homebody.png';
import IdInput from '../components/idInput';
import IdHomeTop from '../components/hometop';
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../Constants';
import LoadingImg from '../images/loading.gif';
import { fireEvent } from '@testing-library/react';
function Register() {
    const [staySigned, setStaySigned] = useState(false)
    const navigate = useNavigate();
    const { profile , setProfile, setToken} = useContext(ShareContext);
    const [user, setUser] =useState({});

    const [err, setErr] = useState('');
    const [loading, setLoading] =useState(false);

    const onRegister=async(e)=>{
      e.preventDefault(); 

        setLoading(true);
        
        try {
          var v={...user, status:'active', userType:'customer'};
          const resp = await fetch(`${Constants.rooturl}/customer`, {
            method: 'POST',
            body:JSON.stringify(v) ,
            headers: {
              'Content-Type': 'application/json, text/plain',
              'Authorization': '',
            },
          });
          
          const data = await resp.json();
          setLoading(false);
          console.log(data);
          if (data.code === 0) {
              //setGazettes(data.gazettes);
              
              setToken(data.token);
              setUser({...user,id:data.id});
              var res={...user,id:data.id};
              setProfile(res);
              //save both token and profile on storage and set current date, so you can compare the date during refresh
             localStorage.setItem("profile",JSON.stringify(res) )
             localStorage.setItem("token",data.token);
             localStorage.setItem("tokendate", new Date().getTime().toString()); //this is keeping the date to try to relunch token or not

              navigate('/dashboard');
          }else {   
            setErr('username/password not correct');
          }
        } catch (error) {
          setLoading(false);
          console.error(error);
          setErr(Constants.serverErr);
         // onMove();
        }

       }

  return (
    <div style={{width:1440, maxWidth:'80%',margin:'auto'}}>
        <div>
        <IdHomeTop />
       <div style={{display:'flex'}}>
            <div style={{width:500, maxWidth:'80%', margin:'auto'}}>
                <div className='idtab'>
                    <div className='tabitem tabactive'> <div>Create Account</div></div>
                    <div  className='tabitem' onClick={()=>navigate('/login')}><div>Log In</div> </div>
                </div>
                <form  onSubmit={onRegister}>
                    <div style={{display:'flex'}}>
                    <div style={{marginRight:10}}><IdInput label="First Name"
                     value={user.firstname} onChange={(e)=>setUser({...user,firstname: e.target.value})} required /></div>
                    <div style={{marginLeft:10}}><IdInput label="Last Name"
                    value={user.lastname} onChange={(e)=>setUser({...user,lastname: e.target.value})}  /></div>
                </div>
               <IdInput label="Email" value={user.email}  onChange={(e)=>setUser({...user,email: e.target.value})} />
              
               <div style={{display:'flex'}}>
                    <div style={{marginRight:10}}> <IdInput label="Password" type="password" 
                    value={user.password} onChange={(e)=>setUser({...user,password: e.target.value})}  /></div>
                    <div style={{marginLeft:10}}> <IdInput label="Re-Password" type="password"
                    value={user.repassword} onChange={(e)=>setUser({...user,repassword: e.target.value})}  /></div>
                </div>
               
               

               {loading && <div  style={{display:'flex', justifyContent:'center', marginBottom:16, flexDirection:'column'}}>
                                  <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
                                  <span style={{ marginLeft:'auto', marginRight:'auto'}}>Loading...</span>
                                </div>}  
                    {!loading && <div style={{display:'flex', justifyContent:'center', marginBottom:16}}>
                      <div style={{marginTop:16, marginBottom:16}}>
                        <button className='greenbutton' style={{width:'100%'}}>Register</button>
                        </div>          
                    
                              </div>} 
                </form>
                
                              <div style={{fontSize:15, color:'red', textAlign:'center', padding:15, fontWeight:'bold'}}>{err}</div>
               <div><span>I Already an account? <Link to={'/login'}>Login</Link></span></div>
            </div>
       </div>  
      
        </div>
      
    </div>
  )
}

export default Register