import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import { ShareContext } from './context/sharecontext';
import { useState, useEffect } from 'react';
import Home from './pages/home';
import Login from './pages/login';
import Register from './pages/register';
import Dashboard from './pages/dashboard';
import Search from './pages/search';
import NinSearch from './pages/nin/nin_search';
import NinPhone from './pages/nin/nin_phone';
import NinSearchResult from './pages/nin/search_result_nin';
import BvnSearchResult from './pages/bvn/bvnresult';
import CacSearchResult from './pages/cac/cacresult';
import PassportSearchResult from './pages/passport/passportresult';
import LicenseSearchResult from './pages/licence/licenceresult';
import VoterSearchResult from './pages/voter/voterresult';
import TinSearchResult from './pages/tin/tinresult';
import Setting from './pages/settings/setting';
import Reports from './pages/report/report';
import CallPayment from './pages/callpaystack';
import ReturnPayment from './pages/returnpaystack';
import ThirdPartyDoc from './pages/thirdpartydoc';
import ResetUser from './pages/resetuser';
import VNinSearchResult from './pages/nin/search_result_vnin';
function App() {

  const [profile, setProfile]=useState(null);
  const [raw, setRaw]=useState(null);
  const [activeType, setActiveType]=useState(null);
  const [token, setToken] = useState(null);
  useEffect(() => { 
    // Constants.seo('','');
    if(profile==null){
      //load from storage
      var pro=localStorage.getItem('profile');
      console.log('from app call');
      console.log(pro);
      if(pro!=null){
          var proo=JSON.parse(pro);
          setProfile(proo);
      }
    }
 }, []);
  return (
    <div className="App">
     <ShareContext.Provider value={{profile,setProfile, raw, setRaw, activeType, setActiveType, token, setToken}}>
      <Router>
        <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/login" exact element={<Login/>} />
          <Route path="/user/reset" exact element={<ResetUser/>} />
          <Route path="/register" exact element={<Register/>} />
          <Route path="/dashboard" exact element={<Dashboard/>} />
          <Route path="/search/:type?" exact element={<Search/>} />  
          <Route path="/ninsearch/nin" exact element={<NinSearch/>} />  
          <Route path="/ninsearch/phone" exact element={<NinPhone/>} /> 
          <Route path="/ninsearch/result" exact element={<NinSearchResult/>} /> 
          <Route path="/searchresult/bvn" exact element={<BvnSearchResult/>} /> 
          <Route path="/searchresult/tin" exact element={<TinSearchResult/>} /> 
          <Route path="/searchresult/cac" exact element={<CacSearchResult/>} /> 
          <Route path="/searchresult/passport" exact element={<PassportSearchResult/>} /> 
          <Route path="/searchresult/license" exact element={<LicenseSearchResult/>} /> 
          <Route path="/searchresult/voter" exact element={<VoterSearchResult/>} /> 
          <Route path="/searchresult/nin-vir" exact element={<VNinSearchResult/>} /> 
          <Route path="/settings" exact element={<Setting/>} />
          <Route path="/reports" exact element={<Reports/>} />
          <Route path="/makepayment" exact element={<CallPayment/>} />
          <Route path="/returnpayment/:refid" exact element={<ReturnPayment/>} />
          <Route path="/thirdpaty/doc" exact element={<ThirdPartyDoc/>} />
        </Routes>
      </Router>
     </ShareContext.Provider>
    </div>
  );
}

export default App;
