import React, { useState,useContext, useEffect } from 'react';


import Logo from '../images/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faCheck, faSquare,  faEye, faEyeSlash, } from '@fortawesome/free-regular-svg-icons';


import HomeImg from '../images/homebody.png';
import IdInput from '../components/idInput';
import { Link, useNavigate } from 'react-router-dom'
import IdHomeTop from '../components/hometop';
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../Constants';
import LoadingImg from '../images/loading.gif';

function ResetUser() {
    const navigate = useNavigate();
    const { profile , setProfile, setToken} = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [showpass, setShowPass] =useState(false);
    const [checkrem, setChechrem] =useState(false);

    const [password, setPassword]=useState('');
    const [email, setEmail]=useState('');
    const [err, setErr] = useState('');
    const [loading, setLoading] =useState(false);
    /* const onLogin=()=>{
        navigate('/dashboard');
    } */
    const onLogin=async(e)=>{
      e.preventDefault(); 
        setLoading(true);
        
        try {
          var v={username:email, password};
          const resp = await fetch(`${Constants.rooturl}/customer/login`, {
            method: 'POST',
            body:JSON.stringify(v) ,
            headers: {
              'Content-Type': 'application/json, text/plain',
              'Authorization': '',
            },
          });
          
          const data = await resp.json();
          setLoading(false);
          console.log(data);
          if (data.code === 0) {
              //setGazettes(data.gazettes);
              setProfile(data.res)
              setToken(data.token);
              //save both token and profile on storage and set current date, so you can compare the date during refresh
             localStorage.setItem("profile",JSON.stringify(data.res) )
             localStorage.setItem("token",data.token);
             localStorage.setItem("tokendate", new Date().getTime().toString()); //this is keeping the date to try to relunch token or not

              navigate('/dashboard');
          }else {   
            setErr('your username/password not correct. Try again');
          }
        } catch (error) {
          setLoading(false);
          console.error(error);
          setErr(Constants.serverErr);
         // onMove();
        }

       }


  return (
    <div style={{width:1440, maxWidth:'80%',margin:'auto'}}>
        <div>
          <IdHomeTop />
       <div style={{display:'flex'}}>
            <div style={{width:500, maxWidth:'80%', margin:'auto'}}>
                <form onSubmit={onLogin}>
                    <div className='idtab'>
                    
                    <div  className='tabitem tabactive'><div><span>Reset Account</span> </div> </div>
                        </div>
                    <IdInput label="Email" value={email} onChange={(e)=>setEmail(e.target.value)}  required />
                        
                    <IdInput label="Password" type={showpass?'text':"password"} value={password} onChange={(e)=>setPassword(e.target.value)}
                    rightIcon={<FontAwesomeIcon icon={showpass?faEye:faEyeSlash } onClick={()=>setShowPass(!showpass)} />} required />

                    <IdInput label="Verify Password" type={showpass?'text':"password"} value={password} onChange={(e)=>setPassword(e.target.value)}
                    rightIcon={<FontAwesomeIcon icon={showpass?faEye:faEyeSlash } onClick={()=>setShowPass(!showpass)} />} required />

                    <div style={{display:'flex', flexDirection:'row', justifyItems:'center', alignContent:'space-between'}}> 
                            <div onClick={()=>setChechrem(!checkrem)}> <FontAwesomeIcon icon={checkrem? faCheckSquare:faSquare}  /> Remember me</div>
                            <div> <span>Forget Password</span></div>
                    </div>
                    {loading && <div  style={{display:'flex', justifyContent:'center', marginBottom:16, flexDirection:'column'}}>
                                  <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
                                  <span style={{ marginLeft:'auto', marginRight:'auto'}}>Loading...</span>
                                </div>}  
                    {!loading && <div style={{display:'flex', justifyContent:'center', marginBottom:16}}>
                       <div style={{marginTop:16, marginBottom:16}}>
                        <button className='greenbutton' style={{width:'100%'}} type="submit">Log In</button>
                        </div>         
                        
                              </div>} 
                              <div style={{fontSize:15, color:'red', textAlign:'center', padding:15, fontWeight:'bold'}}>{err}</div>
                    <div><span>Don't have an account? <Link to={'/register'}> Sign Up</Link></span></div>
                </form>
                
            </div>
       </div>  
      
        </div>
      
    </div>
  )
}

export default ResetUser